/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useGlobalContext } from 'src/context/GlobalContext';
import { LevelPlaces } from 'src/interfaces';
import { HighScore, LevelFinished, VisitStore } from 'src/components/elements/overlay/index';

export const Overlay = () => {
  const [storeScreen, setStoreScreen] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [submitName, setSubmitName] = useState(false);
  const [places, setPlaces] = useState<LevelPlaces[]>([
    {
      level: 1,
      places: [],
    },
    {
      level: 2,
      places: [],
    },
    {
      level: 3,
      places: [],
    },
    {
      level: 4,
      places: [],
    },
  ]);
  const { score, level, setOverlayState, setLevel, name, setName, levelUsers, setLevelUsers, text } = useGlobalContext();

  const { nextButton, nextLevel, startOver, storeText, storeLink, levelFinished } = text;

  useEffect(() => {
    if (buttonClicked === true) {
      setOverlayState(false);
      setButtonClicked(false);
      setTimeout(() => {
        setLevel(level + 1);
      }, 300);
    }
  }, [buttonClicked]);

  useEffect(() => {
    if (submitName === true) {
      const user = {
        name: name.toUpperCase(),
        score,
      };
      const newLevelUsers = [...levelUsers];
      newLevelUsers[level - 1].users.push(user);
      setLevelUsers(newLevelUsers);
      localStorage.setItem('scores', JSON.stringify(newLevelUsers));

      newLevelUsers[level - 1].users.sort((a, b) => a.score - b.score);

      const newPlaces = [...places];
      const temp = [...places];
      newLevelUsers[level - 1].users.forEach((user, index) => {
        if (index === 0) {
          temp[level - 1].places.push({
            place: 1,
            users: [user],
          });
        } else {
          const lastPlace = temp[level - 1].places[temp[level - 1].places.length - 1];
          if (lastPlace.users[0].score === user.score) {
            lastPlace.users.push(user);
          } else {
            temp[level - 1].places.push({
              place: lastPlace.place + 1,
              users: [user],
            });
          }
          setPlaces(newPlaces);
        }
      });
    }
  }, [submitName === true]);

  return (
    <div className="bg-white bg-opacity-[0.85] absolute z-50 left-0 top-0 w-full h-full">
      {storeScreen && level === 1 && <VisitStore setButtonClicked={() => setButtonClicked(true)} storeText={storeText} storeLink={storeLink} nextButtonText={nextButton} />}
      {submitName && name.length === 2 && !storeScreen && (
        <HighScore buttonText1={nextLevel} buttonText2={startOver} level={level} places={places[level - 1].places} setButtonClicked={level === 1 ? () => setStoreScreen(true) : () => setButtonClicked(true)} />
      )}
      {(!submitName || name.length !== 2) && !storeScreen && (
        <LevelFinished texts={levelFinished} buttonText={nextButton} level={level} value={name} onSubmit={() => setSubmitName(name.length === 2 && true)} onChange={setName} />
      )}
    </div>
  );
};
