import styled from '@emotion/styled';
import { FC } from 'react';
import tw from 'twin.macro';

interface Props {
  children: string;
  className?: string;
  disabled?: boolean;
  color?: 'bg-red' | 'bg-blue' | 'bg-yellow' | 'bg-purple';
  onClick: any;
}

const Button: FC<Props> = ({ children, className, onClick, color = 'bg-blue', ...props }) => {
  return (
    <StyledButton
      className={`${className} ${color} `}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      {...props}
    >
      {children}
    </StyledButton>
  );
};

export default Button;

const StyledButton = styled.button`
  ${tw`text-base text-white py-1.5 lg:py-2.5 px-4 lg:px-8 rounded-md min-w-[100px] lg:min-w-[175px] uppercase`}
  font-weight: 600;
  &:disabled {
    ${tw`cursor-not-allowed opacity-30`}
  }
`;
