/* eslint-disable react-hooks/exhaustive-deps */
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { levels } from 'src/content/levels';
import { useGlobalContext } from 'src/context/GlobalContext';
import Grid from 'src/components/elements/Grid';
import ScoreBoard from 'src/components/elements/ScoreBoard';
import { AnimatePresence } from 'framer-motion';
import { Overlay } from './Overlay';
import Button from './elements/Button';
import Card from './elements/cards/Card';
import { useEffect, useState } from 'react';
import Instruction from './elements/cards/Instruction';
import Solution from './elements/cards/Solution';
import HighScorePreview from 'src/components/elements/overlay/HighScorePreview';

const Board = () => {
  const { level, overlayState, resetLevel } = useGlobalContext();
  const cars = levels[level - 1];
  const [instruction, setInstruction] = useState(false);
  const [solution, setSolution] = useState(false);
  const [highScore, setHighScore] = useState(false);

  const { setLevelUsers, text } = useGlobalContext();
  const { highScoreButton, resetButton, solutionButton, rulesButton } = text;

  useEffect(() => {
    const levelUsers = JSON.parse(localStorage.getItem('scores') || '[]');
    if (levelUsers.length !== 0) {
      setLevelUsers(levelUsers);
    }
  }, []);

  const handleInstruction = () => {
    if (solution) setSolution(false);
    if (highScore) setHighScore(false);
    setInstruction((prev) => !prev);
  };

  const handleSolution = () => {
    if (instruction) setInstruction(false);
    if (highScore) setHighScore(false);
    setSolution((prev) => !prev);
  };

  const handleHighScore = () => {
    if (instruction) setInstruction(false);
    if (solution) setSolution(false);
    setHighScore((prev) => !prev);
  };

  return (
    <Wrap>
      <div className="flex flex-col justify-center w-full sm:flex-row">
        <div className="relative flex-1 max-w-screen-md sm:w-full h-max">
          <Grid>{cars}</Grid>
          <AnimatePresence mode="wait">
            {instruction && (
              <Card className="py-4 sm:py-0" isVisible={instruction} onClick={() => setInstruction(false)}>
                <Instruction />
              </Card>
            )}
            {solution && (
              <Card isVisible={solution} onClick={() => setSolution(false)}>
                <Solution level={level} />
              </Card>
            )}
            {highScore && <HighScorePreview onClick={() => setHighScore(false)} />}
          </AnimatePresence>
          <AnimatePresence mode="wait">{overlayState && <Overlay />}</AnimatePresence>
        </div>
        <div className="flex justify-between xxsPlus:flex-col ">
          <ScoreBoard />
          <div className="flex flex-col flex-1 p-2 xxsPlus:flex-none lg:p-5">
            <Button disabled={overlayState} color="bg-red" className='mb-1 sm:mb-2' onClick={() => resetLevel(true)}>
              {resetButton}
            </Button>
            <Button disabled={overlayState} color="bg-yellow" className='mb-1 sm:mb-2' onClick={handleSolution}>
              {solutionButton}
            </Button>
            <Button disabled={overlayState} className='mb-1 sm:mb-2' onClick={handleInstruction}>
              {rulesButton}
            </Button>
            <Button disabled={overlayState} color="bg-purple" onClick={handleHighScore}>
              {highScoreButton}
            </Button>
          </div>
        </div>
      </div>
    </Wrap>
  );
};

export default Board;

const Wrap = styled.div`
  ${tw`relative flex sm:flex-row bg-white justify-center sm:h-[100vh] sm:items-center`}
`;
