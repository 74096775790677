import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FC, useRef } from 'react';
import { getLevelName } from 'src/helpers';
import tw from 'twin.macro';
import Button from '../Button';

interface Props {
  level: number;
  value: string;
  onChange: (value: string) => void;
  onSubmit: () => void;
  texts: {
    headline: string;
    p1: string;
    p2: string;
    p3: string;
  };
  buttonText: string;
}

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const LevelFinished: FC<Props> = ({ level, value, onChange, onSubmit, texts, buttonText }) => {
  //ref for input field
  const inputRef = useRef<HTMLInputElement>(null);

  const { headline, p1, p2, p3 } = texts;

  const handleOnSubmit = () => {
    if (value.length < 2) {
      inputRef.current && inputRef.current.focus();
    }
    onSubmit();
  };

  return (
    <motion.div className="flex flex-col items-center justify-start max-h-full pt-8 text-center xxs:pt-12 xs:pt-16 sm:pt-24" variants={variants} initial="hidden" animate="visible" exit="exit">
      <h2 className="pb-1 text-3xl xs:text-4xl sm:text-5xl md:text-6xl lg:text-7xl sm:pb-2 lg:pb-8 text-blue" dangerouslySetInnerHTML={{ __html: headline }} />
      <div className="xs:text-xl md:text-xl lg:text-2xl text-text">
        <p className="pb-3 sm:pb-6 lg:pb-12">
          {p1} {getLevelName(level)}
          {p2}.
        </p>
        <p className="lg:pb-6" dangerouslySetInnerHTML={{ __html: p3 }} />
        <InputWrap>
          <input
            ref={inputRef}
            className="bg-[transparent] outline-none uppercase w-16 tracking-widest text-center"
            maxLength={2}
            minLength={2}
            autoFocus
            required
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </InputWrap>
        <Button className="absolute bottom-4 right-5 xs:bottom-6 xs:right-8 sm:bottom-10 sm:right-12" onClick={handleOnSubmit}>
          {buttonText}
        </Button>
      </div>
    </motion.div>
  );
};

export default LevelFinished;

const InputWrap = styled.span`
  ${tw`relative max-w-max`}
  &:before {
    content: '';
    ${tw`absolute bottom-0 right-1/2 whitespace-nowrap w-3 sm:w-4 lg:w-1/3 mr-0.5 h-0.5 sm:h-[3px] bg-text`}
  }
  &:after {
    content: '';
    ${tw`absolute bottom-0 left-1/2 ml-0.5 whitespace-nowrap w-3 sm:w-4 lg:w-1/3 h-0.5 sm:h-[3px] bg-text`}
  }
`;
