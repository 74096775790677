import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FC } from 'react';
import tw from 'twin.macro';

interface Props {
  isVisible: boolean;
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

const variants = {
  initial: { opacity: 0, y: '-100%' },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 0.4,
      duration: 0.2,
      type: 'spring',
      damping: 25,
      stiffness: 500,
    },
  },
};

const variantsWrap = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

const Card: FC<Props> = ({ isVisible = false, onClick, children, className }) => {
  return (
    <Wrap variants={variantsWrap} initial="hidden" animate="visible" exit="exit" onClick={onClick}>
      <StyledCard className={`${className}`} initial={'initial'} animate={isVisible && 'visible'} variants={variants}>
        {children}
      </StyledCard>
    </Wrap>
  );
};

export default Card;

const StyledCard = styled(motion.div)`
  ${tw`h-[95%] xs:h-3/4 md:h-2/3 w-auto rounded-[34px] bg-[#E4D18D] shadow-md opacity-0`}
  aspect-ratio: 16 / 25.1;
  @supports not (aspect-ratio) { 
    width: 45%;
  }
`;

const Wrap = styled(motion.div)`
  ${tw`w-full h-full bg-white/80 absolute top-0 left-0 z-50 flex justify-center items-center`}
`;
