import styled from '@emotion/styled';
import { FC } from 'react';
import { useGlobalContext } from 'src/context/GlobalContext';
import tw from 'twin.macro';

const ScoreBoard: FC = () => {
  const { level, score } = useGlobalContext();
  return (
    <Wrap className="xxsPlus:!flex-none">
      <Box className='mb-1 sm:mb-2'>Level {level}</Box>
      <Box>{score}</Box>
    </Wrap>
  );
};

export default ScoreBoard;

const Wrap = styled.div`
  ${tw`flex flex-col items-center justify-center flex-1 p-2 text-2xl lg:text-4xl lg:p-5 text-blue`}
  font-weight: 600;
`;

const Box = styled.div`
  ${tw`flex flex-col items-center justify-center w-full px-2 py-2 bg-white md:px-6 lg:py-5 lg:px-8 xl:px-12 whitespace-nowrap`}
  background: linear-gradient(white 0px, rgb(246, 246, 246) 73%, rgb(224, 224, 224) 100%);
`;
