import level1 from 'src/assets/images/level1.webp';
import level2 from 'src/assets/images/level2.webp';
import level3 from 'src/assets/images/level3.webp';
import level4 from 'src/assets/images/level4.webp';

export const solutions = [
  {
    image: level1,
    solution: ['A↑1', 'X→2', 'B↑2', 'O↑1', 'P→3', 'D↑4', 'C↑4', 'X←2', 'E↑2', 'G←4', 'F←4', 'E↓2', 'P←2', 'O↓3', 'X→5'],
  },
  {
    image: level2,
    solution: ['F↑2', 'P→1', 'E→1', 'C↓2', 'X←2', 'B↓1', 'A→3', 'B↑1', 'X→1', 'C↑3', 'E←1', 'X←1', 'B↓3', 'O←2', 'F↑2', 'A←1', 'D↑2', 'X→5'],
  },
  {
    image: level3,
    solution: [
      'A→1',
      'D↑2',
      'X←1',
      'Q↑2',
      'F→3',
      'Q↓2',
      'X→1',
      'P↓1',
      'D↓3',
      'A←1',
      'X←1',
      'Q↑3',
      'G→1',
      'D↓1',
      'E←3',
      'Q↓1',
      'O↓1',
      'B↓1',
      'C↓1',
      'A→4',
      'O↑1',
      'Q↑1',
      'E→3',
      'D↑1',
      'G←1',
      'Q↓3',
      'X→1',
      'D↑3',
      'X←1',
      'Q↑3',
      'F←3',
      'E←3',
      'O↓3',
      'A←1',
      'C↑1',
      'B↓2',
      'Q↓3',
      'X→5',
    ],
  },
  {
    image: level4,
    solution: [
      'I→1',
      'E↓1',
      'P↑1',
      'Q→3',
      'F↑1',
      'H→1',
      'O↓3',
      'A↓3',
      'B←2',
      'D↑1',
      'X←3',
      'D↓1',
      'C↓1',
      'B→3',
      'D↑1',
      'X→2',
      'O↑3',
      'H←1',
      'F↓1',
      'A↑3',
      'Q←3',
      'P↓1',
      'B→1',
      'X←1',
      'E↑4',
      'X→1',
      'Q→2',
      'A↓3',
      'X←1',
      'E↓1',
      'B←1',
      'P↑1',
      'Q→1',
      'F↑1',
      'H→1',
      'O↓3',
      'X←1',
      'D↓1',
      'B←3',
      'C↑1',
      'E↑1',
      'D↑1',
      'X→3',
      'O↑1',
      'H←1',
      'F↓1',
      'I←1',
      'G←1',
      'Q←1',
      'P↓3',
      'X→2',
    ],
  },
];
