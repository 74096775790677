import styled from '@emotion/styled';
import Logo from 'src/components/elements/svg/Logo';
import { useGlobalContext } from 'src/context/GlobalContext';
import tw from 'twin.macro';

const Instruction = () => {
  const { text } = useGlobalContext();
  const { instruction, instructionDescription } = text;

  return (
    <Wrap>
      <div>
        <div className="px-5 pb-2 sm:pb-3">
          <Logo />
        </div>
        <div className="flex flex-col gap-1" dangerouslySetInnerHTML={{ __html: instruction }} />
      </div>
      <div className="text-center font-bold text-[10px]">{instructionDescription}</div>
    </Wrap>
  );
};

export default Instruction;

const Wrap = styled.div`
  ${tw`w-full h-full flex flex-col text-[12px] justify-between lg:py-5 px-3 overflow-auto`}
  ::-webkit-scrollbar {
    display: none;
  }
`;
