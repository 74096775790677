import React from 'react';
import Board from 'src/components/Board';
import { ContextProvider } from 'src/context/GlobalContext';

function App() {
  return (
    <ContextProvider>
      <Board />
    </ContextProvider>
  );
}

export default App;
