import { LevelUsers, User } from 'src/interfaces';

export const users: User[] = [
  {
    name: 'LL',
    score: 16,
  },
  {
    name: 'KB',
    score: 16,
  },
  {
    name: 'RG',
    score: 16,
  },
  {
    name: 'AG',
    score: 17,
  },
  {
    name: 'SP',
    score: 17,
  },
  {
    name: 'BK',
    score: 17,
  },
];

export const users2: User[] = [
  {
    name: 'MS',
    score: 20,
  },
  {
    name: 'AI',
    score: 24,
  },
  {
    name: 'PK',
    score: 26,
  },
  {
    name: 'PS',
    score: 27,
  },
  {
    name: 'DK',
    score: 26,
  },
  {
    name: 'MS',
    score: 28,
  },
];
export const users3: User[] = [
  {
    name: 'DP',
    score: 48,
  },
  {
    name: 'LP',
    score: 39,
  },
  {
    name: 'BT',
    score: 57,
  },
  {
    name: 'WS',
    score: 57,
  },
  {
    name: 'DH',
    score: 39,
  },
];
export const users4: User[] = [
  {
    name: 'UE',
    score: 60,
  },
  {
    name: 'UH',
    score: 60,
  },
  {
    name: 'JG',
    score: 74,
  },
  {
    name: 'SS',
    score: 73,
  },
  {
    name: 'LD',
    score: 71,
  },
  {
    name: 'RS',
    score: 76,
  },
];

export const levelUsers: LevelUsers[] = [
  {
    level: 1,
    users: users,
  },
  {
    level: 2,
    users: users2,
  },
  {
    level: 3,
    users: users3,
  },
  {
    level: 4,
    users: users4,
  },
];
