export type Position = [number, number];
export interface Car {
  type: CarEnum;
  position: Position;
  image?: string;
  isTruck?: boolean;
  isVertical?: boolean;
}
export interface HOCCarProps {
  position: Position;
  isTruck?: boolean;
  isVertical?: boolean;
}
export enum CarEnum {
  CAR_RED = 'CAR_RED',
  CAR_BLUE = 'CAR_BLUE',
  CAR_GREEN = 'CAR_GREEN',
  CAR_PURPLE = 'CAR_PURPLE',
  CAR_ORANGE = 'CAR_ORANGE',
  CAR_PINK = 'CAR_PINK',
  CAR_TURQUOISE = 'CAR_TURQUOISE',
  CAR_GRAY = 'CAR_GRAY',
  CAR_BEIGE = 'CAR_BEIGE',
  CAR_YELLOW = 'CAR_YELLOW',
  TRUCK_YELLOW = 'TRUCK_YELLOW',
  TRUCK_PURPLE = 'TRUCK_PURPLE',
  TRUCK_BLUE = 'TRUCK_BLUE',
}

export interface User {
  name: string;
  score: number;
}

export interface Place {
  place: number;
  users: User[];
}

export interface LevelUsers {
  level: number;
  users: User[];
}

export interface LevelPlaces {
  level: number;
  places: Place[];
}
