import React, { FC } from 'react';
import { useGlobalContext } from 'src/context/GlobalContext';
import { User } from 'src/interfaces';

interface Props {
  place: number;
  users: User[];
}

const Place: FC<Props> = ({ place, users }) => {
  const { text } = useGlobalContext();
  const { placeText, moves } = text;
  return (
    <div className="flex text-text xs:text-xl sm:text-xl lg:text-2xl border-b border-text gap-[86px] py-2 sm:py-4 px-3 min-w-[271px] xs:min-w-[304px] sm:min-w-[334px] lg:min-w-[405px] justify-between">
      <p className="whitespace-nowrap text-purple">
        {placeText} {place}
      </p>
      <ul className="">
        {users.map((user, index) => (
          <li key={index} className="flex justify-between gap-8 md:gap-12">
            <span className="lg:min-w-[50px]">{user.name}</span>
            <span className="whitespace-nowrap">
              {user.score} {moves}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Place;
