import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FC, useEffect, useState } from 'react';
import tw from 'twin.macro';
import Place from '../Place';
import { Place as PlaceInterface } from 'src/interfaces';
import { getLevelName } from 'src/helpers';
import { useGlobalContext } from 'src/context/GlobalContext';

interface Props {
  onClick: () => void;
}

const HighScorePreview: FC<Props> = ({ onClick }) => {
  const { level, levelUsers } = useGlobalContext();
  const [places, setPlaces] = useState<PlaceInterface[]>([]);

  useEffect(() => {
    const newPlaces: PlaceInterface[] = [];
    levelUsers[level - 1].users.sort((a, b) => a.score - b.score);

    levelUsers[level - 1].users.forEach((user, index) => {
      if (index === 0) {
        newPlaces.push({
          place: 1,
          users: [user],
        });
      } else {
        const lastPlace = newPlaces[newPlaces.length - 1];
        if (user.score === lastPlace.users[0].score) {
          lastPlace.users.push(user);
        } else {
          newPlaces.push({
            place: lastPlace.place + 1,
            users: [user],
          });
        }
      }
    });

    setPlaces(newPlaces);
  }, [levelUsers, level]);

  const variantsWrap = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <Wrap onClick={onClick} variants={variantsWrap} initial="hidden" animate="visible" exit="exit">
      <div className="flex flex-col items-center justify-start max-h-full pt-8 text-center xxs:pt-12 xs:pt-14 sm:pt-16 lg:pt-24">
        <h2 className="pb-1 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl sm:pb-2 lg:pb-8 text-blue">
          Highscore <br /> {getLevelName(level)}
        </h2>
        <PlacesWrap>
          {places.map((levelUsers, index) => (
            <Place key={index} place={levelUsers.place} users={levelUsers.users} />
          ))}
        </PlacesWrap>
      </div>
    </Wrap>
  );
};

export default HighScorePreview;

const PlacesWrap = styled.div`
  ${tw`flex flex-col items-center mb-16 overflow-x-hidden overflow-y-auto xs:mb-20 sm:mb-24 lg:mb-32`}

  & > *:last-child {
    ${tw`border-none`}
  }
`;

const Wrap = styled(motion.div)`
  ${tw`absolute top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-white/80`}
`;
