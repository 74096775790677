import Car from 'src/components/elements/Car';
import { Car as ICar, CarEnum, HOCCarProps } from 'src/interfaces';
import carRed from 'src/assets/images/car-red.webp';
import carBlue from 'src/assets/images/car-blue-vertical.webp';
import carGreen from 'src/assets/images/car-green.webp';
import carGreenVertical from 'src/assets/images/car-green-vertical.webp';
import carPurple from 'src/assets/images/car-purple.webp';
import carPurpleVertical from 'src/assets/images/car-purple-vertical.webp';
import carOrange from 'src/assets/images/car-orange.webp';
import carOrangeVertical from 'src/assets/images/car-orange-vertical.webp';
import carPink from 'src/assets/images/car-pink.webp';
import carPinkVertical from 'src/assets/images/car-pink-vertical.webp';
import carTurquoiseVertical from 'src/assets/images/car-turquoise-vertical.webp';
import truckYellow from 'src/assets/images/truck-yellow.webp';
import truckYellowVertical from 'src/assets/images/truck-yellow-vertical.webp';
import truckPurple from 'src/assets/images/truck-purple.webp';
import truckPurpleVertical from 'src/assets/images/truck-purple-vertical.webp';
import truckBlue from 'src/assets/images/truck-blue.webp';
import truckBlueVertical from 'src/assets/images/truck-blue-vertical.webp';
import carTurquoise from 'src/assets/images/car-turquoise.webp';
import carGray from 'src/assets/images/car-gray.webp';
import carBeige from 'src/assets/images/car-beige.webp';
import carYellow from 'src/assets/images/car-yellow.webp';

const CarRed: React.FC<HOCCarProps> = ({ ...props }) => <Car image={carRed} {...props} />;
const CarBlue: React.FC<HOCCarProps> = ({ ...props }) => <Car image={carBlue} {...props} />;
const CarGreen: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? carGreenVertical : carGreen} {...props} />;
const CarPurple: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? carPurpleVertical : carPurple} {...props} />;
const CarOrange: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? carOrangeVertical : carOrange} {...props} />;
const CarPink: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? carPinkVertical : carPink} {...props} />;
const CarTurquoise: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? carTurquoiseVertical : carTurquoise} {...props} />;
const CarGray: React.FC<HOCCarProps> = ({ ...props }) => <Car image={carGray} {...props} />;
const TruckYellow: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? truckYellowVertical : truckYellow} isTruck {...props} />;
const TruckPurple: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? truckPurpleVertical : truckPurple} isTruck {...props} />;
const TruckBlue: React.FC<HOCCarProps> = ({ ...props }) => <Car image={!!props.isVertical ? truckBlueVertical : truckBlue} isTruck {...props} />;
const CarBeige: React.FC<HOCCarProps> = ({ ...props }) => <Car image={carBeige} {...props} />;
const CarYellow: React.FC<HOCCarProps> = ({ ...props }) => <Car image={carYellow} {...props} />;

const { CAR_RED, CAR_BLUE, CAR_GREEN, CAR_PURPLE, CAR_ORANGE, CAR_PINK, CAR_TURQUOISE, CAR_GRAY, CAR_BEIGE, TRUCK_YELLOW, TRUCK_PURPLE, TRUCK_BLUE, CAR_YELLOW } = CarEnum;

const CarEnumMap = new Map<CarEnum, React.FC<HOCCarProps>>([
  [CarEnum.CAR_RED, CarRed],
  [CarEnum.CAR_BLUE, CarBlue],
  [CarEnum.CAR_GREEN, CarGreen],
  [CarEnum.CAR_PURPLE, CarPurple],
  [CarEnum.CAR_ORANGE, CarOrange],
  [CarEnum.CAR_PINK, CarPink],
  [CarEnum.CAR_TURQUOISE, CarTurquoise],
  [CarEnum.CAR_GRAY, CarGray],
  [CarEnum.TRUCK_YELLOW, TruckYellow],
  [CarEnum.TRUCK_PURPLE, TruckPurple],
  [CarEnum.TRUCK_BLUE, TruckBlue],
  [CarEnum.CAR_BEIGE, CarBeige],
  [CarEnum.CAR_YELLOW, CarYellow],
]);

const mapDataToLevel = (level: number, array: ICar[]) =>
  array.map((car) => {
    const CarItem = CarEnumMap.get(car.type)!;
    return <CarItem key={car.type + level} {...car} />;
  });

const level1 = mapDataToLevel(1, [
  {
    type: CAR_RED,
    position: [0, 2],
  },
  {
    type: CAR_ORANGE,
    position: [5, 2],
    isVertical: true,
  },
  {
    type: CAR_GREEN,
    position: [4, 4],
  },
  {
    type: TRUCK_YELLOW,
    position: [4, 1],
    isVertical: true,
  },
  {
    type: TRUCK_PURPLE,
    position: [0, 3],
  },
  {
    type: CAR_BLUE,
    position: [0, 4],
    isVertical: true,
  },
  {
    type: CAR_PINK,
    position: [1, 4],
    isVertical: true,
  },
  {
    type: CAR_PURPLE,
    position: [2, 4],
    isVertical: true,
  },
  {
    type: CAR_TURQUOISE,
    position: [2, 1],
    isVertical: true,
  },
  {
    type: CAR_GRAY,
    position: [4, 5],
  },
]);
const level2 = mapDataToLevel(2, [
  {
    type: CAR_RED,
    position: [2, 2],
  },
  {
    type: CAR_TURQUOISE,
    position: [0, 0],
  },
  {
    type: CAR_GREEN,
    position: [5, 4],
    isVertical: true,
  },
  {
    type: CAR_PURPLE,
    position: [0, 4],
  },
  {
    type: TRUCK_PURPLE,
    position: [2, 4],
  },
  {
    type: TRUCK_YELLOW,
    position: [3, 1],
  },
  {
    type: CAR_PINK,
    position: [4, 2],
    isVertical: true,
  },
  {
    type: CAR_ORANGE,
    position: [2, 0],
    isVertical: true,
  },
  {
    type: CAR_BLUE,
    position: [0, 1],
    isVertical: true,
  },
]);

const level3 = mapDataToLevel(3, [
  {
    type: CAR_RED,
    position: [1, 2],
  },
  {
    type: CAR_TURQUOISE,
    position: [0, 0],
  },
  {
    type: CAR_GREEN,
    position: [0, 4],
  },
  {
    type: CAR_GRAY,
    position: [0, 5],
  },
  {
    type: TRUCK_PURPLE,
    position: [5, 2],
    isTruck: true,
    isVertical: true,
  },
  {
    type: TRUCK_YELLOW,
    position: [3, 0],
    isTruck: true,
    isVertical: true,
  },
  {
    type: TRUCK_BLUE,
    position: [2, 3],
    isTruck: true,
    isVertical: true,
  },
  {
    type: CAR_ORANGE,
    position: [4, 0],
    isVertical: true,
  },
  {
    type: CAR_BLUE,
    position: [5, 0],
    isVertical: true,
  },
  {
    type: CAR_PURPLE,
    position: [3, 3],
  },
  {
    type: CAR_PINK,
    position: [0, 2],
    isVertical: true,
  },
]);
const level4 = mapDataToLevel(4, [
  {
    type: CAR_RED,
    position: [3, 2],
  },
  {
    type: CAR_TURQUOISE,
    position: [1, 0],
    isVertical: true,
  },
  {
    type: CAR_GREEN,
    position: [2, 4],
    isVertical: true,
  },
  {
    type: CAR_GRAY,
    position: [4, 4],
  },
  {
    type: CAR_BEIGE,
    position: [0, 5],
  },
  {
    type: TRUCK_PURPLE,
    position: [5, 1],
    isTruck: true,
    isVertical: true,
  },
  {
    type: TRUCK_YELLOW,
    position: [0, 0],
    isTruck: true,
    isVertical: true,
  },
  {
    type: TRUCK_BLUE,
    position: [0, 3],
  },
  {
    type: CAR_ORANGE,
    position: [2, 0],
  },
  {
    type: CAR_BLUE,
    position: [4, 0],
    isVertical: true,
  },
  {
    type: CAR_PURPLE,
    position: [3, 3],
    isVertical: true,
  },
  {
    type: CAR_PINK,
    position: [2, 1],
    isVertical: true,
  },
  {
    type: CAR_YELLOW,
    position: [3, 5],
  },
]);

export const levels = [level1, level2, level3, level4];
