/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { useGlobalContext } from 'src/context/GlobalContext';

interface Props {
  children?: React.ReactNode;
}

const Grid: FC<Props> = ({ children }) => {
  const cellsPerRow = 6;
  const cells = Array.from(Array(cellsPerRow * cellsPerRow).keys());

  const { cellWidth, setCellWidth, gridGap, setGridGap } = useGlobalContext();
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleResize() {
      const cellWidth = (document.getElementById('cell-0')?.clientWidth && document.getElementById('cell-0')?.clientWidth) || 0;
      setCellWidth(cellWidth);
      window.innerWidth < 768 ? setGridGap(4) : setGridGap(8);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Wrap>
      <StyledGrid ref={ref} cellWidth={cellWidth} gridGap={gridGap}>
        {children}
        {cells.map((cell) => (
          <Cell id={`cell-${cell}`} cellWidth={cellWidth} className="aspect-square" key={cell} />
        ))}
      </StyledGrid>
    </Wrap>
  );
};

export default Grid;

const StyledGrid = styled.div<{ cellWidth: number; gridGap: number }>`
  ${tw`grid relative overflow-visible grid-cols-6 grid-rows-6 gap-1 md:gap-2 max-w-screen-lg m-1.5 xs:m-3`}

  #cell-17 {
    ${tw`relative`}
    &:after {
      content: '';
      position: absolute;
      height: ${({ cellWidth, gridGap }) => `${cellWidth + 2 * gridGap}px`};
      ${tw`xs:w-[18px] w-1.5 bg-white right-[-14px] xs:right-[-30px] -top-2`}
    }
  }
`;

const Cell = styled.div<{ cellWidth: number}>`
  ${tw`w-full bg-[ #6E7392]`}
  @supports not (aspect-ratio) {   
    height: ${({ cellWidth }) => `${cellWidth}px`};
  }
  box-shadow: inset 0 0 10px 3px #00000070;
`;

const Wrap = styled.div`
  ${tw`bg-[#42486cd9] border-[#575C7C] border-[6px] xs:border-[18px]`}
  box-shadow: inset 0 0 18px 3px #00000070;
`;
