import { motion } from 'framer-motion';
import { FC } from 'react';
import image from 'src/assets/images/rush_hour.webp';
import Button from '../Button';

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
  exit: { opacity: 0 },
};

interface Props {
  setButtonClicked: () => void;
  storeText: string;
  storeLink: string;
  nextButtonText: string;
}

const VisitStore: FC<Props> = ({ setButtonClicked, storeLink, storeText, nextButtonText }) => (
  <motion.div className="flex flex-col items-center justify-start max-h-full pt-8 text-center xxs:pt-12 xs:pt-16 sm:pt-24" variants={variants} initial="hidden" animate="visible" exit="exit">
    <a className="block mx-auto max-w-max aspect-square w-[62%] xs:w-1/2 md:w-[62%] overflow-hidden" href={storeLink} target={'blank'}>
      <img className="w-full h-full transition-all duration-300 ease-in-out hover:scale-105" src={image} alt="" />
    </a>
    <a className="pt-2 text-2xl transition-colors duration-200 text-text sm:pt-4 lg:text-4xl hover:text-blue" href={storeLink} target={'blank'}>
      {storeText}
    </a>
    <Button className="absolute bottom-4 right-5 xs:bottom-6 xs:right-8 sm:bottom-10 sm:right-12" onClick={setButtonClicked}>
      {nextButtonText}
    </Button>
  </motion.div>
);

export default VisitStore;
