import styled from '@emotion/styled';
import tw from 'twin.macro';
import { FC } from 'react';
import { solutions } from 'src/content/levelsSoluton';
import Logo from 'src/components/elements/svg/Logo';

interface Props {
  level: number;
}

const Solution: FC<Props> = ({ level }) => {
  return (
    <>
      <div className={`${level > 2 && (level > 3 ? 'hidden' : 'hidden lg:block')} px-8 pt-5 pb-2 sm:pb-3 `}>
        <Logo />
      </div>
      <Wrap className={`${level > 2 && (level > 3 ? 'pt-5' : 'pt-5 lg:pt-0')}`}>
        <div className="relative pb-3 overflow-visible px-7 sm:pb-5">
          <img className="rounded-lg" src={solutions[level - 1].image} alt="" />
          <p className="absolute rotate-[270deg] text-[10px] bottom-1/2 right-3 sm:right-0 translate-x-2/3 -translate-y-1/2">© 2010 ThinkFun Inc.</p>
          <Level className="absolute left-0 text-3xl bottom-5 text-red">{level}</Level>
        </div>
        <div>
          <MovesWrap className="">
            <p className="absolute top-0 text-red -left-2">&#9658;</p>
            {solutions[level - 1].solution.map((item, index) => (
              <span key={index} className="text-center border-b border-b-red">
                {item}
              </span>
            ))}
          </MovesWrap>
        </div>
      </Wrap>
    </>
  );
};

export default Solution;

const Wrap = styled.div`
  ${tw`flex flex-col w-full h-full px-5 pb-5 sm:px-8`}
`;

const MovesWrap = styled.div`
  ${tw`text-[10px] xs:text-[12px] relative font-bold grid grid-cols-5 justify-between`}

  span:last-child {
    ${tw`relative`}
    &::after {
      content: url("data:image/svg+xml,%3Csvg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='11' height='11' rx='5.5' fill='%23E7000E'/%3E%3C/svg%3E%0A");
      ${tw`absolute top-[1px] -right-2.5`}
    }
  }
`;

const Level = styled.h3`
  ${tw`absolute left-0 text-3xl bottom-5 text-red`}
  text-shadow: 1.5px 1.5px #000000;
`;
