export const getLevelName = (type: number) => {
  const level: {
    [key: number]: string;
  } = {
    1: 'Beginner',
    2: 'Intermediate',
    3: 'Advanced',
    4: 'Expert',
  };
  return `“${level[type]}”` || 'Unknown';
};
