import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { FC } from 'react';
import tw from 'twin.macro';
import Place from '../Place';
import { Place as PlaceInterface } from 'src/interfaces';
import { getLevelName } from 'src/helpers';
import Button from '../Button';

interface Props {
  places: PlaceInterface[];
  setButtonClicked: () => void;
  level: number;
  buttonText1: string;
  buttonText2: string;
}

const HighScore: FC<Props> = ({ places, level, buttonText1, buttonText2, setButtonClicked }) => {
  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <motion.div className="flex flex-col items-center justify-start max-h-full pt-8 text-center xxs:pt-12 xs:pt-14 sm:pt-16 lg:pt-24" variants={variants} initial="hidden" animate="visible" exit="exit">
      <h2 className="pb-1 text-2xl xs:text-3xl sm:text-4xl md:text-5xl lg:text-6xl sm:pb-2 lg:pb-8 text-blue">
        Highscore <br /> {getLevelName(level)}
      </h2>
      <PlacesWrap>
        {places.map((place, index) => (
          <Place key={index} place={place.place} users={place.users} />
        ))}
      </PlacesWrap>
      <Button className="absolute bottom-4 right-5 xs:bottom-6 xs:right-8 sm:bottom-10 sm:right-12" onClick={setButtonClicked}>
        {level !== 4 ? buttonText1 : buttonText2}
      </Button>
    </motion.div>
  );
};

export default HighScore;

const PlacesWrap = styled.div`
  ${tw`flex flex-col items-center mb-16 overflow-x-hidden overflow-y-auto xs:mb-20 sm:mb-24 lg:mb-32`}

  & > *:last-child {
    ${tw`border-none`}
  }
`;
