/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { ActionType, useGlobalContext } from 'src/context/GlobalContext';
import { motion, PanInfo, useMotionValue } from 'framer-motion';
import { CarEnum } from 'src/interfaces';

interface Props {
  type?: CarEnum;
  position: [number, number];
  isTruck?: boolean;
  image: string;
  isVertical?: boolean;
}

const Car: FC<Props> = ({ type, position, isTruck = false, image, isVertical = false }) => {
  const { gridDistribution, setGridDistribution, cellWidth, dispatch, overlayState, setOverlayState, level, reset, resetLevel, gridGap } = useGlobalContext();

  const handleX = useMotionValue(position[0] * cellWidth + position[0] * gridGap + 1);
  const handleY = useMotionValue(position[1] * cellWidth + position[1] * gridGap + 1);
  const snapX = useMotionValue(position[0]);
  const snapY = useMotionValue(position[1]);
  const [positionState, setPositionState] = useState(position);
  const [movingFlag, setMovingFlag] = useState(false);
  const [startMovePosition, setStartMovePosition] = useState(position);

  const touchPointsCount = useRef(0);
  useEffect(() => {
    setPositionState(position);
    resetLevel(false);
  }, [reset]);

  useEffect(() => {
    const [x, y] = position;
    const newMatrix = [...gridDistribution];
    newMatrix[y][x] = 1;
    if (isVertical) {
      if (isTruck) {
        newMatrix[y + 1][x] = 1;
        newMatrix[y + 2][x] = 1;
      }
      newMatrix[y + 1][x] = 1;
    }

    if (!isVertical) {
      if (isTruck) {
        newMatrix[y][x + 1] = 1;
        newMatrix[y][x + 2] = 1;
      }
      newMatrix[y][x + 1] = 1;
    }

    setGridDistribution(newMatrix);
  }, [level, reset === true]);

  useEffect(() => {
    handleX.set(positionState[0] * cellWidth + positionState[0] * gridGap + 1);
    handleY.set(positionState[1] * cellWidth + positionState[1] * gridGap + 1);
  }, [cellWidth, positionState]);

  const newArray = [...gridDistribution];

  const checkCollision = (x: number, y: number): boolean => {
    const length = isTruck ? 3 : 2;
    const startPosition = isVertical ? positionState[1] : positionState[0];

    const allowed = [];

    for (let i = startPosition + length; i < 6; i++) {
      if (gridDistribution[isVertical ? i : y][isVertical ? x : i] === 0) {
        allowed.push(i);
      }
    }

    for (let i = startPosition - 1; i >= 0; i--) {
      if (gridDistribution[isVertical ? i : y][isVertical ? x : i] === 0) {
        allowed.push(i);
      }
    }

    for (let i = startPosition + length; i < 6; i++) {
      if (gridDistribution[isVertical ? i : y][isVertical ? x : i] === 1) {
        for (let j = i + 1; j < 6; j++) {
          const index = allowed.indexOf(j);
          if (index > -1) {
            allowed.splice(index, 1);
          }
        }
      }
    }

    for (let i = startPosition - 1; i >= 0; i--) {
      if (gridDistribution[isVertical ? i : y][isVertical ? x : i] === 1) {
        for (let j = i - 1; j >= 0; j--) {
          const index = allowed.indexOf(j);
          if (index > -1) {
            allowed.splice(index, 1);
          }
        }
      }
    }

    if (type === CarEnum.CAR_RED) (positionState[0] === 4 || positionState[0] === 5) && allowed.push(5) && allowed.push(6);

    allowed.push(startPosition);
    allowed.push(startPosition + 1);
    isTruck && allowed.push(startPosition + 2);
    allowed.sort((a, b) => a - b);

    isTruck ? allowed.splice(allowed.length - 2, 2) : allowed.pop();

    if (isVertical) {
      if (allowed.includes(y)) {
        const column = [];
        for (let i = 0; i < 6; i++) {
          column.push(newArray[i][x]);
        }
        if (y > positionState[1]) {
          column[y - 1] = 0;
          column[y + 1] = 1;
          isTruck && (column[y + 2] = 1);
          for (let i = 0; i < 6; i++) {
            newArray[i][x] = column[i];
          }
          return true;
        }
        if (y < positionState[1]) {
          column[y] = 1;
          isTruck ? (column[y + 3] = 0) : (column[y + 2] = 0);
          for (let i = 0; i < 6; i++) {
            newArray[i][x] = column[i];
          }
          return true;
        }
        return false;
      } else {
        return false;
      }
    } else {
      if (allowed.includes(x)) {
        if (x > positionState[0]) {
          newArray[positionState[1]][x - 1] = 0;
          newArray[positionState[1]][x + 1] = 1;
          isTruck && (newArray[positionState[1]][x + 2] = 1);
          return true;
        }
        if (x < positionState[0]) {
          newArray[positionState[1]][x] = 1;
          isTruck ? (newArray[positionState[1]][x + 3] = 0) : (newArray[positionState[1]][x + 2] = 0);
          return true;
        }
        return false;
      } else {
        return false;
      }
    }
  };

  const handleSnapToGridCells = (info: PanInfo) => {
    const maxPosition = isTruck ? 3 : type === CarEnum.CAR_RED ? 7 : 4;
    if (touchPointsCount.current > 1) return;
    if (!isVertical) {
      snapX.set(Math.abs(Math.round(info.point.x / (cellWidth + gridGap))));
      if (snapX.getPrevious() - snapX.get() >= 1 && info.offset.x < 0 && positionState[0] >= 1 && checkCollision(positionState[0] - 1, positionState[1]) && !overlayState) {
        !movingFlag && setStartMovePosition([positionState[0], positionState[1]]);
        setPositionState((curr) => [curr[0] - 1, curr[1]]);
        touchPointsCount.current <= 1 && setGridDistribution(newArray);
        setMovingFlag(true);
      }
      if (snapX.getPrevious() - snapX.get() <= -1 && info.offset.x > 0 && positionState[0] < maxPosition && checkCollision(positionState[0] + 1, positionState[1]) && !overlayState) {
        !movingFlag && setStartMovePosition([positionState[0], positionState[1]]);
        setPositionState((curr) => [curr[0] + 1, curr[1]]);
        touchPointsCount.current <= 1 && setGridDistribution(newArray);
        setMovingFlag(true);
      }
    }
    if (isVertical) {
      snapY.set(Math.abs(Math.round(info.point.y / (cellWidth + gridGap))));
      if (snapY.getPrevious() - snapY.get() >= 1 && info.offset.y < 0 && positionState[1] >= 1 && checkCollision(positionState[0], positionState[1] - 1) && !overlayState) {
        !movingFlag && setStartMovePosition([positionState[0], positionState[1]]);
        setPositionState((curr) => [curr[0], curr[1] - 1]);
        touchPointsCount.current <= 1 && setGridDistribution(newArray);
        setMovingFlag(true);
      }
      if (snapY.getPrevious() - snapY.get() <= -1 && info.offset.y > 0 && positionState[1] < maxPosition && checkCollision(positionState[0], positionState[1] + 1) && !overlayState) {
        !movingFlag && setStartMovePosition([positionState[0], positionState[1]]);
        setPositionState((curr) => [curr[0], curr[1]+1]);
        touchPointsCount.current <= 1 && setGridDistribution(newArray);
        setMovingFlag(true);
    
      }
    }
  };

  const handleWin = () => {
    if (type === CarEnum.CAR_RED && positionState[0] > 4) {
      setOverlayState(true);
    }
  };

  //debounce handle

  const handlePan = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    event.stopPropagation();
    if (touchPointsCount.current > 1) {
      event.preventDefault();
      return;
    }

    touchPointsCount.current <= 1 && handleSnapToGridCells(info);
  };
  const handleSetScore = (event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) => {
    if (touchPointsCount.current > 1) {
      return;
    }
    if (startMovePosition[0] === positionState[0] && startMovePosition[1] === positionState[1]) {
      return;
    }
    movingFlag && dispatch({ type: ActionType.INCREMENT_SCORE });
    setMovingFlag(false);
    setTimeout(() => type === CarEnum.CAR_RED && handleWin(), 0);
  };

  return (
    <StyledCar
      className="absolute top-0 left-0 z-50 col-span-2 cursor-pointer car touch-none"
      isVertical={isVertical}
      gridGap={gridGap}
      isTruck={isTruck}
      style={{
        x: handleX,
        y: handleY,
      }}
      onPan={handlePan}
      onPanEnd={handleSetScore}
      onTouchMove ={(event) => (touchPointsCount.current = event.touches.length)}
      onTouchEnd={(event) => (touchPointsCount.current = event.touches.length)}
    >
      <div className="w-full h-full pointer-events-none" style={{ backgroundImage: `url(${image})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', backgroundPosition: 'center center' }}></div>
    </StyledCar>
  );
};

export default Car;

const StyledCar = styled(motion.div)<{ isVertical: boolean; isTruck: boolean; gridGap: number }>`
  transition-property: transform;
  transition-duration: 50ms;
  -webkit-tap-highlight-color: transparent;
  @media (min-width: 768px) {
    transition-duration: 100ms;
  }
  transform-origin: center center;
  width: ${({ isVertical, isTruck }) => (isVertical ? 'calc((100% - 40px) / 6)' : isTruck ? 'calc((100% - 40px) / 6 * 3 + 12px)' : 'calc((100% - 40px) / 6 * 2 + 8px)')};
  height: ${({ isVertical, isTruck }) => (isVertical ? (isTruck ? 'calc((100% - 40px) / 6 * 3 + 12px)' : 'calc((100% - 40px) / 6 * 2 + 8px)') : 'calc((100% - 40px) / 6)')};
`;
